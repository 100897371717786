import React from 'react'
import eatwonderfully from '../../images/eat-wonderfully.png';
import "./Wunderfully.scss";

const WunderFully = () => {
  return (
   <div class="wunder-row">
        <div class="wunder-row-inner">
		    <div class="wunder-row-title">
                <img src={eatwonderfully}/>
            </div>
		    <p>Everything is better with Wunderbar® Bologna. We craft our German-style bologna with fine cuts of beef and our unique blend of spices to bring you the slightly sweet taste you love. Our bologna varieties have not changed since 1972 and we are proud to be #1* in grocer's delis across the country. Enjoy our bologna cold or fry it for a smoky, deliciously caramelized taste. Don't stop there—everything is better with bologna!</p>
		<div class="center">
            <a class="round-button" href="">LEARN MORE</a>
        </div>
		<div class="wunder-row-footer">
            * Nielsen data as of 1/31/19
        </div>
		    </div>
    </div>
   
  )
}

export default WunderFully