import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import "./OurProducts.scss";
import title from '../../images/row-4-title.png'
import beefBologna from '../../images/product-2.png'
import germanBrand from '../../images/product-1.png'

const OurProducts = () => {
  return (
    <div class="ourProducts">
        <Container className>
          <Row>
              <div class="ourProducts__title">
                <img src={title}/>
              </div>
          </Row>
          <Row className="ourProducts__container justify-content-md-center">
              <Col xs="12" md="6" lg="6" xl="5">
                <div class="ourProducts__product">
                    <div class="ourProducts__product__title">
                      <h4>
                        German Brand Bologna
                        <small>Made with pork, chiken, beef</small>
                      </h4>
                    </div>
                    <div class="ourProducts__product__image">
                      <img src={germanBrand}/>
                    </div>
                    <div class="ourProducts__product__specs">
                      <div class="ourProducts__product__specs__item">
                        <span>Calories</span>
                        <span class="ourProducts__product__specs__item__value">180</span>
                        <span></span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Cholesterol</span>
                        <span class="ourProducts__product__specs__item__value">40</span>
                        <span>MG</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Sat Fat</span>
                        <span class="ourProducts__product__specs__item__value">5</span>
                        <span>G</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Carbs</span>
                        <span class="ourProducts__product__specs__item__value">5</span>
                        <span>G</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Sodium</span>
                        <span class="ourProducts__product__specs__item__value">610</span>
                        <span>MG</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Protein</span>
                        <span class="ourProducts__product__specs__item__value">6</span>
                        <span>G</span>
                      </div>
                    </div>
                    <div class="ourProducts__product__nutritional">
                      <div class="ourProducts__product__nutritional__header">
                        <div class="ourProducts__product__nutritional__header__item">SERV SIZE: <span>2 oz</span></div>
                        <div class="ourProducts__product__nutritional__header__item">CALORIES: <span>180</span></div>
                        <div class="ourProducts__product__nutritional__header__item">FAT CALORIES: <span>130</span></div>
                      </div>
                      <div class="ourProducts__product__nutritional__info">
                        <Row>
                          <Col xs="12" md="6" lg="6" className="p-0">
                            <ul>
                              <li>
                                <small>AMOUNT/SERVING</small>
                                <span class="ourProducts__product__nutritional__info--right"><small>%DV*</small></span>
                              </li>
                              <li>
                                <strong>TOTAL FAT</strong> 15G
                                <span class="ourProducts__product__nutritional__info--right">23%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                SATURATED FAT 5G
                                <span class="ourProducts__product__nutritional__info--right">25%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                TRANS FAT 0G
                              </li>
                              <li>
                                <strong>CHOLESTEROL</strong> 40MG 
                                <span class="ourProducts__product__nutritional__info--right">13%</span>
                              </li>
                              <li>
                                <strong>SODIUM</strong> 610MG 
                                <span class="ourProducts__product__nutritional__info--right">25%</span>
                              </li>
                            </ul>
                          </Col>
                          <Col xs="12" md="6" lg="6" className="p-0">
                            <ul>
                              <li class="ourProducts__product__nutritional__info--hidden">
                                <small>AMOUNT/SERVING</small>
                                <span class="ourProducts__product__nutritional__info--right"><small>%DV*</small></span>
                              </li>
                              <li>
                                <strong>TOTAL CARBS</strong> 5G
                                <span class="ourProducts__product__nutritional__info--right">2%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                DIETARY FIBER 0G
                                <span class="ourProducts__product__nutritional__info--right">0%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                SUGARS 4G
                              </li>
                              <li>
                                <strong>PROTEIN</strong> 6G
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      <div class="ourProducts__product__nutritional__footer">
                        <div class="ourProducts__product__nutritional__footer__item">VITAMIN A: <span>0%</span></div>
                          <div class="ourProducts__product__nutritional__footer__item">VITAMIN C: <span>0%</span></div>
                          <div class="ourProducts__product__nutritional__footer__item">CALCIUM: <span>4%</span></div>
                          <div class="ourProducts__product__nutritional__footer__item">IRON: <span>4%</span></div>
                      </div>
                    </div>
                    <div class="ourProducts__product__footNote">
                      <p>*Percent Daily Values are based on a 2,000 calorie diet.</p>
                    </div>
                  </div>
              </Col>
              <Col xs="12" md="6" lg="6" xl="5" className="ourProducts__product--last">
                <div class="ourProducts__product">
                    <div class="ourProducts__product__title">
                      <h4>Beef Bologna</h4>
                    </div>
                    <div class="ourProducts__product__image">
                      <img src={beefBologna}/>
                    </div>
                    <div class="ourProducts__product__specs">
                      <div class="ourProducts__product__specs__item">
                        <span>Calories</span>
                        <span class="ourProducts__product__specs__item__value">170</span>
                        <span></span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Cholesterol</span>
                        <span class="ourProducts__product__specs__item__value">30</span>
                        <span>MG</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Sat Fat</span>
                        <span class="ourProducts__product__specs__item__value">6</span>
                        <span>G</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Carbs</span>
                        <span class="ourProducts__product__specs__item__value">4</span>
                        <span>G</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Sodium</span>
                        <span class="ourProducts__product__specs__item__value">630</span>
                        <span>MG</span>
                      </div>
                      <div class="ourProducts__product__specs__item">
                        <span>Protein</span>
                        <span class="ourProducts__product__specs__item__value">6</span>
                        <span>G</span>
                      </div>
                    </div>
                    <div class="ourProducts__product__nutritional">
                      <div class="ourProducts__product__nutritional__header">
                        <div class="ourProducts__product__nutritional__header__item">SERV SIZE: <span>2 oz</span></div>
                        <div class="ourProducts__product__nutritional__header__item">CALORIES: <span>170</span></div>
                        <div class="ourProducts__product__nutritional__header__item">FAT CALORIES: <span>130</span></div>
                      </div>
                      <div class="ourProducts__product__nutritional__info">
                        <Row>
                          <Col xs="12" md="6" lg="6" className="p-0">
                            <ul>
                              <li>
                                <small>AMOUNT/SERVING</small>
                                <span class="ourProducts__product__nutritional__info--right"><small>%DV*</small></span>
                              </li>
                              <li>
                                <strong>TOTAL FAT</strong> 14G
                                <span class="ourProducts__product__nutritional__info--right">22%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                SATURATED FAT 6G
                                <span class="ourProducts__product__nutritional__info--right">30%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                TRANS FAT 0G
                              </li>
                              <li>
                                <strong>CHOLESTEROL</strong> 30MG 
                                <span class="ourProducts__product__nutritional__info--right">10%</span>
                              </li>
                              <li>
                                <strong>SODIUM</strong> 630MG 
                                <span class="ourProducts__product__nutritional__info--right">26%</span>
                              </li>
                            </ul>
                          </Col>
                          <Col xs="12" md="6" lg="6" className="p-0">
                            <ul>
                              <li class="ourProducts__product__nutritional__info--hidden">
                                <small>AMOUNT/SERVING</small>
                                <span class="ourProducts__product__nutritional__info--right"><small>%DV*</small></span>
                              </li>
                              <li>
                                <strong>TOTAL CARBS</strong> 4G
                                <span class="ourProducts__product__nutritional__info--right">1%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                DIETARY FIBER 0G
                                <span class="ourProducts__product__nutritional__info--right">0%</span>
                              </li>
                              <li class="ourProducts__product__nutritional__info--indent">
                                SUGARS 2G
                              </li>
                              <li>
                                <strong>PROTEIN</strong> 6G 
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                      <div class="ourProducts__product__nutritional__footer">
                        <div class="ourProducts__product__nutritional__footer__item">VITAMIN A: <span>0%</span></div>
                        <div class="ourProducts__product__nutritional__footer__item">VITAMIN C: <span>0%</span></div>
                        <div class="ourProducts__product__nutritional__footer__item">CALCIUM: <span>2%</span></div>
                        <div class="ourProducts__product__nutritional__footer__item">IRON: <span>4%</span></div>
                      </div>
                    </div>
                    <div class="ourProducts__product__footNote">
                      <p>*Percent Daily Values are based on a 2,000 calorie diet.</p>
                    </div>
                  </div>
              </Col>
          </Row>
        </Container>
      </div>
  )
}

export default OurProducts