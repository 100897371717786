import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import HeroImage from "../HeroImage/HeroImage";
import large from "../../images/homepage/row-1-l.jpg";
import medium from "../../images/homepage/row-1-m.jpg";
import small from "../../images/homepage/row-1-s.jpg";

const HeroHome = () => {
  return (
    <HeroImage
      children={
        <>
          <img
            className="container--row--large"
            src={large}
            loading="lazy"
            alt="The Fried Baloney Sandwich"
          />
          <img
            className="container--row--medium"
            src={medium}
            loading="lazy"
            alt="The Fried Baloney Sandwich"
          />
          <img
            className="container--row--small"
            src={small}
            loading="lazy"
            alt="The Fried Baloney Sandwich"
          />
          <div className="container--row--title">
            <StaticImage
              className="container--row--title--image"
              src="../../images/homepage/row-1-title.png"
              loading="lazy"
              alt="The Fried Baloney Sandwich"
            />
          </div>
        </>
      }
    />
  );
};

export default HeroHome;
