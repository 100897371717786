import React from "react";
import "./DeliciousBanner.scss";
import small from "../../images/delicious-small.png";
import medium from "../../images/delicious-medium.png";
import large from "../../images/delicious-large.png";
import CTAButton from "../CTAButton/CTAButton";

const DeliciousBanner = () => {
  return (
    <div className="delicious_banner">
      <img
        className="delicious_banner__header delicious_banner__header--small"
        src={small}
        alt="Delicious Then, Delicious Now-small"
      />
      <img
        className="delicious_banner__header delicious_banner__header--medium"
        src={medium}
        alt="Delicious Then, Delicious Now-medium"
      />
      <img
        className="delicious_banner__header delicious_banner__header--large"
        src={large}
        alt="Delicious Then, Delicious Now-large"
      />
      <p className="text">
        Bologna? Yes, please! Everyone's lunchbox hero has grown up and is
        showing up in surprising ways. We know it was delicious then and it's
        just as delicious now. Serve the sweet taste of the German-style bologna
        that you grew up eating. Wunderbar® Bologna is available sliced-to-order
        at your grocer's deli. It’s just too good NOT to share!
      </p>
      <div className="center">
        <CTAButton text="CHECK OUT OUR FEATURED RECIPE" link="/" />
      </div>
    </div>
  );
};

export default DeliciousBanner;
