import React from "react";
import DeliciousBanner from "../components/DeliciousBanner/DeliciousBanner";
import HeroHome from "../components/HeroHome/HeroHome";
import Layout from "../components/Layout";
import OurProducts from "../components/OurProducts/OurProducts";
import Wunderfully from "../components/Wunderfully/Wunderfully";

const Home = () => {
  return (
    <Layout title="" description="">
      <HeroHome />
      <DeliciousBanner />
      <Wunderfully />
      <OurProducts />
    </Layout>
  );
};

export default Home;
